import './locationPlayerHistory.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { locationPlayerHistoryLayout as layout } from './LocationPlayerHistoryLayout';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Page from 'app-components/Page/Page';
import TableListed from 'components/Tables/TableListed';

const LocationPlayerHistory = () => {
    const { id, type } = useParams();
    const { setHeader, updateLocationBreadcrumbs, 
            locationTabs, updateLocationHeader} = useProfile();
    const { updateTable } = useTables();
    const { callAPI, callAPIGet, updateLocationTabs } = useUtils();
    const [ transactions, setTransactions ] = useState();
    const [ balances, setBalances ] = useState();
    const [ defaultDateOne, setDefaultDateOne ] = useState();
    const [ defaultDateTwo, setDefaultDateTwo ] = useState();
    const [ maskOne, setMaskOne ] = useState([true]);
    const [ maskTwo, setMaskTwo ] = useState([true]);
    const tableOne = 'location-player-transactions-' + type;
    const tableTwo = 'location-player-balances-' + type;
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) {return}
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        const range = getDefaultDateRange(tableOne);
        setDefaultDateOne(range);
        getTransactionsData(range, true);
    }, []);

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        details && setHeader(details.locationName);
        updateLocationBreadcrumbs(details);
        const types = data?.kioskTypes;
        updateLocationTabs(types); 
        updateLocationHeader(data?.locationDetails);
    }

    const getTransactionsData = (range, first) => {
        setMaskOne([true]);
        updateTable('dates', tableOne, range);
        const params = getAPIDates(range);
        params.locationId = id;
        params.PlayerPin = type;
        callAPIGet('report-LocationPlayerTransactions', playerTransactionsCallback, params, first);
    }

    const playerTransactionsCallback = (data, first) => {
        setTransactions(data?.playerTransactions || []);
        setMaskOne([false]);
        if (first) {
            const range = getDefaultDateRange(tableTwo);
            setDefaultDateTwo(range);
            getBalancesData(range)
        }
    }

    const getBalancesData = (range) => {
        setMaskTwo([true]);
        updateTable('dates', tableTwo, range);
        const params = getAPIDates(range);
        params.locationId = id;
        params.playerPin = type;
        callAPI('report-LocationPlayerBalances', playerBalancesCallback, params);
    }

    const playerBalancesCallback = (data) => {
        setBalances(data?.transactions || []);
        setMaskTwo([false]);
    }

    const onDateRangePick = (table, range) => {
        table === 'transactions' 
            ? getTransactionsData(range) 
            : getBalancesData (range);
    }

    return (
        <Page
            subPage={t.players}
            tabs={locationTabs.tabs}
        >
            <div className='card-label'>{t.playerTransactions}</div>
            <TableListed
                id={tableOne}
                classes='full'
                data={transactions}
                layout={layout.transactions}
                masked={maskOne?.[0]}
                searchBarContent={<>
                    <DateRangePicker
                        classes='quarter'
                        defaultVal={defaultDateOne}
                        label={t.dateRange}
                        callback={(data) => {onDateRangePick('transactions', data)}}
                    />
                </>}
            />
            <div className='card-label'>{t.playerBalances}</div>
            <TableListed
                id={tableTwo}
                classes='full'
                data={balances}
                layout={layout.balances}
                masked={maskTwo?.[0]}
                searchBarContent={<>
                    <DateRangePicker
                        classes='quarter'
                        defaultVal={defaultDateTwo}
                        label={t.dateRange}
                        callback={(data) => {onDateRangePick('balances', data)}}
                    />
                </>}
            />
        </Page>
    )
}

export default LocationPlayerHistory;
