import { useEffect, useRef } from 'react';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Modal from 'components/Modal/Modal';
import ManifestDetails from 'pages/Manifests/ManifestDetails/ManifestDetails';

const AddManifestModal = () => {
    const { setModal } = useModal();
    const { setRefreshPage } = useProfile();
    const { updateNotice } = useUtils();
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
    }, []);

    const onSubmit = (data) => {
        setModal(false);
        updateNotice(data);
        setRefreshPage([true]);
    }

    const onCancel = (data) => {
        setModal(false);
    }

    return (
        <Modal title={t.addManifest} classes='add-modal'>
            <ManifestDetails newManifest={true} newSubmit={onSubmit} newCancel={onCancel}/>
        </Modal>
    )
}

export default AddManifestModal;
