import { useEffect, useRef, useState } from 'react';
import { deepCopy } from 'components/shared/componentUtils';
import { roles } from 'shared/data';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Modal from 'components/Modal/Modal';
import UserDetails from 'pages/Users/UserDetails/UserDetails';
import UserPassword from 'pages/Users/UserPassword/UserPassword';

const AddUserModal = () => {
    const { getAuth } = useAuth();
    const { clearAllInputs } = useInputs();
    const { removeModal } = useModal();
    const { setRefreshPage } = useProfile();
    const { callAPI, updateNotice } = useUtils();
    const [ stage, setStage ] = useState(0);
    const [ userData, setUserData ] = useState();
    const [ rolesList, setRolesList ] = useState();
    const [ currentRole, setCurrentRole ] = useState();
    const [ disabled, setDisabled ] = useState(false);
    const runOnce = useRef();
    const role = useRef(getAuth().role);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('rolesListGet', rolesListGetCallback, {loggedInUserRoleId: role.current.id});
    }, []);

    const rolesListGetCallback = ( data ) => {
        if (data?.isSuccessful) {
            const cleanedList = [];
            const labels = roles.labels;
            data.roles.forEach((role)=>{
                cleanedList.push({
                    id: role.id,
                    role: labels[role.id],
                })
            })
            setRolesList(cleanedList);
        } else {
            setRolesList([])
        }
    }

    const onSubmit = (data) => {
        switch (stage) {
            case 0:
                setUserData(data);
                setStage(stage + 1);
                break;
            case 1: 
                const newData = deepCopy(userData);
                newData.password = data.password;
                setUserData(newData);
                setStage(stage + 1);
                break;
            default: 
                removeModal();
                return;
        }
    }

    const onCancel = () => {
        clearAllInputs();
        removeModal();
    }

    const onRoleSelect = ( data ) => {
        setCurrentRole(data.value);
    }

    const onSubmitClick = (event, setLoading) => {
        setLoading(true);
        setDisabled(true);
        userData.roleId = currentRole;
        userData.address.county = 'text';
        const props = {
            event: event,
            setLoading: setLoading
        }
        callAPI('userCreate', userCreateCallback, userData, props);
    }

    const userCreateCallback = (data, props) => {
        props.setLoading(false);
        setDisabled(false);
        if (data?.isSuccessful) {
            updateNotice(data);
            setRefreshPage([true]);
            removeModal();
        } else {
            updateNotice(data, props);
        }
    }

    const onBack = () => {
        setStage(stage -1);
    }

    return (
        <Modal title={t.addUser} classes='add-modal'>
            <div className='new-stage-marker-container grid'>
                <div className={`third new-stage-marker ${stage === 0 ? 'blue' : stage < 1 ? 'grey' : 'green'}`}>
                    {t.details}
                </div>
                <div className={`third new-stage-marker ${stage === 1 ? 'blue' : stage < 2 ? 'grey' : 'green'}`}>
                    {t.password}
                </div>
                <div className={`third new-stage-marker ${stage === 2 ? 'blue' : stage < 3 ? 'grey' : 'green'}`}>
                    {t.permissions}
                </div>
            </div>
            {stage === 0 && <UserDetails newUser={true} newData={userData} newSubmit={onSubmit} newCancel={onCancel}/>}
            {stage === 1 && <UserPassword newUser={true} newSubmit={onSubmit} newBack={onBack} newCancel={onCancel}/>}
            {stage === 2 && <div className='add-user-permissions grid'>
                <Dropdown
                    id='permissions-users-role'
                    classes='full'          
                    label={t.role}
                    defaultVal={currentRole}
                    data={rolesList}
                    labelProp='role'
                    callback={onRoleSelect}/>
                <div className='full modal-buttons-container grid'>
                    <Button 
                        classes='red half' 
                        disabled={disabled}
                        onClick={onBack}
                    >{t.back}</Button>
                    <Button 
                        classes='blue half last' 
                        type='submit'
                        onClick={(event, setLoading)=>{onSubmitClick(event, setLoading)}}
                        disabled={!currentRole}
                    >{t.submit}</Button>
                </div>
            </div>}
        </Modal>
    )
}

export default AddUserModal;
