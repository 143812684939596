import './deviceReports.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
import DeviceTransactions from './DeviceTransactions/DeviceTransactions';
import DeviceGames from './DeviceGames/DeviceGames';
import DeviceKiosk from './DeviceKiosk/DeviceKiosk';
import DeviceRecyclerHistory from './DeviceRecyclerHistory/DeviceRecyclerHistory';

const deviceReports = {
    'transactions': <DeviceTransactions/>,
    'games': <DeviceGames/>,
    'kiosk': <DeviceKiosk/>,
    'recycler': <DeviceRecyclerHistory/>
}

const DeviceReports = () => {
    const { id, type } = useParams();
    const { navTo } = useComponents();
    const { updateDropdown } = useInputs();
    const { deviceTabs } = useProfile();
    const { callAPIGet, updateDeviceTabs } = useUtils();
    const [ reportsList, setReportsList ] = useState();
    const [ currentReport, setCurrentReport ] = useState();

    useEffect(() => {   
        if (!currentReport && type) {
            updateDropdown('device-reports', type, getDropdownLabel(type));
        }
    }, [])

    useEffect(() => {
        const params = {terminalId: id}
        callAPIGet('deviceDetails', deviceDetailsCallback, params);
        updateDropdown('device-reports', type, getDropdownLabel(type));
        !currentReport && setCurrentReport(type ? type : 'transactions');
    }, [type])

    useEffect(() => {
        if (currentReport && type !== currentReport) {
            navTo(`/device/${id}/reports/${currentReport}`);
        }
    }, [currentReport]);

    const deviceDetailsCallback = (data) => {
        const type = data?.terminalType;
        if (!type) {return};
        updateDeviceTabs(type.id)
        setReportsList(getReportsList(type.id))
    }

    const getReportsList = (type) => {
        const list = [];
        type !== 5 && list.push({value: 'transactions', label: t.transactions})
        if (type === 3 || type === 6) {list.push({value: 'games', label: t.gamePerformance})};
        type === 2 && list.push({value: 'kiosk', label: t.kiosk})
        // kioskDevices.includes(type) && list.push({value: 'recycler', label: t.recyclerHistory})
        return list;
    }

    const onReportSelect = (data) => {
        setCurrentReport(data.value)
    }

    const getDropdownLabel = (val) => {
        switch(val) {
            case 'transactions':
                return t.transactions;
            case 'games':
                return t.gamePerformance;
            case 'kiosk':
                return t.kiosk;
            case 'recycler':
                return t.recyclerHistory;
            default:
                return t.transactions;
        }
    }

    return (
        <Page
            subPage={t.reports}
            tabs={deviceTabs.tabs}
            contentClasses='grid device-reports'
        >
            <Card hideLabel={true} classes='full grid margin-bottom'>
                <Dropdown
                    id='device-reports'
                    classes='quarter'
                    label={t.selectedReport}
                    noPermission={reportsList?.length <= 1}
                    hideErrors={true}
                    data={reportsList}
                    callback={onReportSelect}/>
            </Card>
            {deviceReports[currentReport]}
        </Page>
    )
}

export default DeviceReports;
