import './locationPlayers.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { locationPlayersLayout as layout } from './LocationPlayersLayout';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Page from 'app-components/Page/Page';
import TableListed from 'components/Tables/TableListed';

const LocationPlayers = () => {
    const { id } = useParams();
    const { setHeader, updateLocationBreadcrumbs, 
            locationTabs, updateLocationHeader} = useProfile();
    const { updateTable } = useTables();
    const { callAPI, updateLocationTabs } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const tableId = 'location-players-' + id;
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) {return}
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        getReportData(range);
    }, []);

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        details && setHeader(details.locationName);
        updateLocationBreadcrumbs(details);
        const types = data?.kioskTypes;
        updateLocationTabs(types); 
        updateLocationHeader(data?.locationDetails);
    }

    const getReportData = (range) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = id;
        callAPI('report-LocationPlayers', playerBalancesCallback, params);
    }

    const playerBalancesCallback = (data) => {
        setTableRecords(data?.playerBalances || []);
        setMasked([false]);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
    }

    return (
        <Page
            subPage={t.players}
            tabs={locationTabs.tabs}
        >
            <TableListed
                id={tableId}
                classes='full'
                data={tableRecords}
                layout={layout}
                masked={masked?.[0]}
                searchBarContent={<>
                    <DateRangePicker
                        classes='quarter'
                        defaultVal={defaultDates}
                        label={t.dateRange}
                        callback={onDateRangePick}
                    />
                </>}
            />
        </Page>
    )
}

export default LocationPlayers;
