import {useEffect, useRef} from 'react';
import {text as t} from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Modal from 'components/Modal/Modal';
import SourceDetails from 'pages/Sources/SourceDetails/SourceDetails';

const AddSourceModal = () => {
    const {setModal} = useModal();
    const {setSources} = useProfile();
    const {callAPI, updateNotice} = useUtils();
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
    }, []);

    const onSubmit = () => {
        const params = { updateSourceId: null }
        callAPI('sources', sourcesCallback, params);
    }

    const sourcesCallback = (data) =>{
        setModal(false);
        updateNotice(data);
        setSources(data?.tableData ? data.tableData : []);
    }

    const onCancel = (data) => {
        setModal(false);
    }

    return (
        <Modal title={t.addSource} classes='add-modal'>
            <SourceDetails newSource={true} newSubmit={onSubmit} newCancel={onCancel}/>
        </Modal>
    )
}

export default AddSourceModal;
