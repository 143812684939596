import { useEffect } from 'react';
import { updateFilters } from 'app-components/Table/tableUtils';
import { deepCopy, isValid } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import ContextMenu from 'app-components/ContextMenu/ContextMenu';
import Input from 'components/Input/Input';

const TableSearchBar = ({layout, tableStates, setTableStates, searchBarContent, editable}) => {
    const { inputValues, updateInput } = useInputs(); 
    const id = layout.id;
    const fieldId = id + '-table-search-input';
    const inputId = layout.id +'-search-bar-input';  
    
    useEffect(()=> {
        const searchData = tableStates?.[id]?.filters?.inputValue
        updateInput(fieldId, searchData?.value || '');
    }, [])

    useEffect(()=> {
        if (!isValid(inputValues[fieldId])) {return}
        updateSeachInput(inputValues[fieldId]);
    }, [inputValues])

    const updateSeachInput = (value) => {
        if (!tableStates?.[id]) {return};
        const inputValue = {
            name: inputId,
            value: value
        }
        const newStates = deepCopy(tableStates);
        newStates[id].filters.inputValue = inputValue;
        newStates[id].currentPage = [1];
        updateFilters(newStates, setTableStates);
    }

    return (
        <div className={`table-search-bar ${layout.hideSearch? 'hide' : ''}`}>
            <div className='table-search-bar-content grid'>
                {searchBarContent}
            </div>
            <div className='table-search-right'>
                {(layout.contextMenu && editable) &&  <ContextMenu layout={layout}/>}
                {layout.hideInput ? '' : <Input
                    id={fieldId}
                    classes='search-bar-input'
                    type='searchInput'
                    defaultVal={tableStates?.[id]?.filters?.inputValue?.value}
                    label={t.inputSearch + ':'}
                    hideErrors={true}
                />}
            </div>
        </div>
    )
}

export default TableSearchBar;
