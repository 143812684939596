import Button from 'components/Button/Button';
import Link from 'app-components/Link/Link';
import useAuth from 'hooks/Auth/useAuth';
import Checkbox from 'components/Checkbox/Checkbox';
import Input from 'components/Input/Input';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import { deepCopy, hasInvalidSpecial, hasLower, hasNumber, hasUpper, hasValidSpecial } from 'components/shared/componentUtils';
import { useEffect, useRef, useState } from 'react';
import bg from 'shared/img/login-bg.jpg';
import logo from 'shared/img/primero-logo.png';
import { text as t } from 'shared/text';
import './resetPassword.scss';


const ResetPassword = () => {
    const { callResetPassword } = useAuth();
    const { inputValues, changeInputErrors, validateForm, changeInputValues } = useInputs();
    const { navTo } = useComponents();
    const runOnce = useRef(false);
    const skippedMount = useRef(false);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const passwordId = 'portal-reset-password';
    const passwordConfirmId = 'portal-reset-password-confirm';

    useEffect(() => {
        if (runOnce.current) {
            return;
        }
        runOnce.current = true;
        const appElement = document.getElementById('App');
        Object.assign(appElement.style, {
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        });

        return () => {
            if (skippedMount.current) {
                Object.assign(appElement.style, {});
            } else {
                skippedMount.current = true;
            }
        };
    }, []);

    useEffect(() => {
        const val = inputValues?.[ passwordId ] ? inputValues[ passwordId ] : '';
        if (!val) {
            setIsValidPassword(false);
        }
        let invalid = 0;
        const newValues = deepCopy(inputValues);
        if (val.length >= 8) { newValues.minLength = true; } else {
            newValues.minLength = false;
            invalid++;
        }
        if (hasUpper(val)) { newValues.oneUppercase = true; } else {
            newValues.oneUppercase = false;
            invalid++;
        }
        if (hasLower(val)) { newValues.oneLowercase = true; } else {
            newValues.oneLowercase = false;
            invalid++;
        }
        if (hasNumber(val)) { newValues.oneNumber = true; } else {
            newValues.oneNumber = false;
            invalid++;
        }
        if (hasValidSpecial(val)) { newValues.oneSpecial = true; } else {
            newValues.oneSpecial = false;
            invalid++;
        }
        if (!hasInvalidSpecial(val)) { newValues.invalidSpecial = false; } else {
            newValues.invalidSpecial = true;
            invalid++;
        }
        changeInputValues(newValues);
        setIsValidPassword(invalid === 0);
    }, [inputValues[ passwordId ]]);

    useEffect(() => {
        if (inputValues[ passwordId ] !== inputValues[ passwordConfirmId ]) {
            changeInputErrors({ [ passwordConfirmId ]: t.passwordConfirmationError });
        }
    }, [inputValues[ passwordConfirmId ]]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress);
        return () => {
            document.removeEventListener('keydown', onKeyPress);
        };
    }, []);

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            const submitButton = document.getElementById('reset-password-submit-button');
            submitButton.click();
        }
    };

    const onSubmit = (event, setLoading) => {
        const password = inputValues[ passwordId ];
        const passwordConfirm = inputValues[ passwordConfirmId ];


        setLoading(true);
        let query = new URLSearchParams(window.location.search); // useParams not working
        let token = query.get('token');
        const params = {
            organizationId: 1,
            password: password,
            confirmation: passwordConfirm,
            secret: token,
        };
        const props = {
            password: password,
            confirmation: passwordConfirm,
            secret: token,
            setLoading: setLoading,
        };
        callResetPassword(params, onSuccess, onError, props);

    };

    const onSuccess = (data) => {
        if (data.data[ 'errorMessage' ] !== '') {
            changeInputErrors({ [ passwordId ]: data.data[ 'errorMessage' ] });
        } else {
            navTo('/login', ['replace']);
        }
    };

    const onError = (err) => {
        const response = err.response;
        const status = response.status;
        let message;
        switch (status) {
            case 404:
                message = t.errorNoEndpoint;
                break;
            default:
                return t.errGeneral;
        }
        changeInputErrors({ [ passwordId ]: message });
    };

    return (
      <div className="reset-password">
          <div className="reset-password-form-container grid">
              <div className="reset-password-logo full centered">
                  <img className="reset-password-logo-image" src={logo} alt="logo" />
              </div>
              <div className="full centered">
                  {t.passwordResetInstructions}
              </div>
              <form className="full grid">

                  <Input
                    classes="reset-password-input half centered"
                    id={passwordId}
                    type="password"
                    label={t.newPassword}
                  />
                  <Input
                    classes="reset-password-input half centered"
                    id={passwordConfirmId}
                    type="password"
                    label={t.confirmNewPassword}
                  />

              </form>
              <div className="half centered">
                  <Checkbox classes="full left password-req" id="minLength" displayOnly={true} label="8 character minimum" />
                  <Checkbox classes="full left password-req" id="oneUppercase" displayOnly={true} label="one uppercase" />
                  <Checkbox classes="full left password-req" id="oneLowercase" displayOnly={true} label="one lowercase" />
                  <Checkbox classes="full left password-req" id="oneNumber" displayOnly={true} label="one number" />
                  <Checkbox classes="full left password-req" id="oneSpecial" displayOnly={true} label="one special character" />
              </div>

              <Button
                id="reset-password-submit-button"
                classes="reset-password-submit blue third centered"
                type="submit"
                disabled={!isValidPassword || inputValues[ passwordId ] !== inputValues[ passwordConfirmId ]}
                onClick={(event, setLoading) => {
                    onSubmit(event, setLoading);
                }}
              >
                  {t.submit}
              </Button>
              <div className="reset-cancel-link-container full centered">
                  <Link label={t.cancel} link={'/login'}>
                      {t.cancel}
                  </Link>
              </div>
          </div>
      </div>
    );

};

export default ResetPassword;
