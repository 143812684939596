import 'pages/Companies/CompanyDevices/companyDevices.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { companyDevicesLayout as layout } from './CompanyDevicesLayout';
import { companyTabsList } from 'shared/tabs';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const CompanyDevices = () => {
    const { id } = useParams();
    const { setHeader } = useProfile();
    const { callAPI } = useUtils();
    const [ tableData, setTableData ] = useState();
    const [ tableLoading, setTableLoading ] = useState([true]);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = {companyId: id};
        callAPI('companyDetails', detailsCallback, {id: id})
        callAPI('devices', devicesListCallback, params);

    }, []);

    const detailsCallback = (data) => {
        const name = data?.companyDetailsData?.companyInfo?.companyName;
        setHeader(name ? name : '');
    }

    const devicesListCallback = (data) => {
        setTableLoading([false]);
        const list = data?.list ? data.list : [];
        setTableData(list);
    }

    return (
        <Page
            subPage={t.devices}
            tabs={companyTabsList}
        >
            <Table
                data={tableData}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default CompanyDevices;
