import { text as t } from 'shared/text';
import { cashValues } from './data';
import { getSubtotalSum } from 'components/Tables/tableUtils';

export const baseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.toLowerCase().includes('dev-portal.primerogames.com')) {
        return 'https://dev-api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('qa-portal.primerogames.com')) {
        return 'https://qa-api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('stage-portal.primerogames.com')) {
        return 'https://stage-api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('portal.primerogames.com')) {
        return 'https://api.trueroute.com';
    }

    if (hostname.toLowerCase().includes('localhost')) { /// for connection to dev env from local
        return 'https://dev-api.trueroute.com';
    }

    return 'http://localhost';
};

export const filterDevicesByValue = (data, value) => {
    const newList = [];
    data.forEach((item) => {
        if (item.terminalTypeId === value) {
            newList.push(item)
        }
    })
    return newList;
}

export const getActionType = (prop) => {
    switch (prop) {
        case 'reboot':
            return 1;
        case 'pauseService':
            return 2;
        case 'startService':
            return 3;
        case 'sendLogs':
            return 4;
        case 'executeScript':
            return 7;
        case 'openSupportTunnel':
            return 9;
        case 'resetPOSAdminPassword':
            return 10;
        default:
            return null
    }
}

export const getCashData = (data, country) => {
    const array = [];
    const keys = Object.keys(data);
    keys.forEach((key) => {
        const row = {};
        const count = data[key];
        row.denomination = getDenomination(key, country);
        row.count = count
        row.value = count * cashValues[key].val
        array.push(row)
    })
    return array;
}

const getDenomination = (key, country) => {
    switch (country) {
        case 'UG':
            return cashValues[key].val;
        default:
            return cashValues[key].label
    }
}

export const getDeviceCash = (data) => {
    delete data.errorMessage;
    delete data.headers;
    delete data.httpStatusCode;
    delete data.isSuccessful;
    delete data.responseTypeEnum;
    const array = [];
    const keys = Object.keys(data);
    keys.forEach((key) => {
        const row = {};
        const count = data[key];
        const denom = key.replace('denom_', '');
        row.denomination = `$${denom}`;
        row.count = count
        row.value = count * denom;
        array.push(row)
    })
    return array;
}

export const getDeviceLabel = (type) => {
    switch (type) {
        case 1:
            return t.primePay
        case 2:
            return t.grc
        case 3:
            return t.piggyBank
        case 4:
            return t.pos
        case 5:
            return t.monitor
        case 6:
            return t.piggyBankUG
        case 7:
            return t.bingo
        case 8:
            return t.primepay2
        default:
            return t.unknown
    }
}

export const getFinanceTypes = (types) => {
    return {
        primepayOnly: types?.includes(1) && types.length === 1,
        grc: types?.includes(2),
        deluxe: types?.includes(3),
        pos: types?.includes(4),
        monitor: types?.includes(5),
        ug: types?.includes(6),
        bingo: types?.includes(7),
        primepay2: types?.includes(8),
    }
}

export const getFinanceData = (types, data, records) => {
    const list = getFinanceTypes(types);
    if (list.primepayOnly) {
        return {
            data: { cashOut: data.cashOut },
            formats: ['currency'],
            headers: { cashOut: t.cashOut }
        }
    }

    const cardData = {
        cashIn: data.cashIn,
        cashOut: data.cashOut,
        profit: data.profit,
        hold: data.holdPercentage
    }
    const formats = ['currency', 'currency', 'currency', 'percentage-int'];
    const headers = {
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        profit: t.profit,
        hold: t.hold
    }
    if (list.pos) {
        cardData.reversal = data.refund;
        cardData.promo = data.promo;
        formats.push('currency');
        formats.push('currency');
        headers.reversal = t.refunds;
        headers.promo = t.promos;
    }
    if (list.grc || list.deluxe || list.pos) {
        cardData.escrow = data.escrow;
        formats.push('currency');
        headers.escrow = t.playerCredits
    }
    if (list.deluxe) {
        cardData.spinRate = getSpinRate(records, data.cashIn);
        formats.push('align-right');
        headers.spinRate = t.spinRate;
    }
    return {
        data: cardData,
        formats: formats,
        headers: headers
    }
}

const getSpinRate = (records, cashIn) => {
    const sum = getSubtotalSum('totalCashIn', records);
    return (cashIn ? sum / cashIn : 0).toFixed(2) + 'x';
}

export const getFinancesCard = (data, order) => {
    const object = {};
    order.forEach((key) => {
        object[key] = data[key];
    })
    return object;
}

export const isProduction = () => {
    return window.location.hostname.toLowerCase() === 'pwp.trueroute.com'
}