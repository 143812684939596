import { text as t } from '../text';

export const excelHeaders = {
    averageBet: t.averageBet,
    averageWin: t.averageWin,
    cashIn: t.cashIn,
    cashOut: t.cashOut,
    holdPercentage: t.holdPercentage,
    numberOfOneDollarBills: '$1',
    numberOfFiveDollarBills: '$5',
    numberOfTenDollarBills: '$10',
    numberOfTwentyDollarBills: '$20',
    numberOfFiftyDollarBills: '$50',
    numberOfHundredDollarBills: '$100',
    deviceId: t.deviceId,
    endDate: t.endDate,
    gameTypeId: 'Game Type Id',
    gameTypeName: t.game,
    gameTypePercentage: 'Game Type Percentage',
    highestWin: t.highestWin,
    hitFrequency: 'Hit Freq (1 in X)',
    hitFrequencyPercentage: 'Hit Freq %',
    locationId: t.locationId,
    numberOfWins: 'Win Count',
    profit: t.profit,
    promo: t.promo,
    refund: t.refund,
    rtp: t.rtp,
    startDate: t.startDate,
    terminalId: t.deviceId,
    terminalStatusTypeId: 'Device Status ID',
    terminalStatusTypeDescription: 'Device Status Description',
    terminalTypeDescription: 'Device Description',
    terminalTypeId: 'Device Type',
    totalVTP: t.vtp,
    totalPlays: t.plays,
    totalWins: t.totalWins,
}