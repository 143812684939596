import './link.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useComponents from 'components/hooks/Components/useComponents';
import Icon from '../Icon/Icon';

const Link = ({ route, type, navData, rowData, children }) => {
    const { id } = useParams();
    const { navTo } = useComponents();
    const [ loading ] = useState(false);
    const [ URL, setURL]  = useState();

    useEffect(() => {
        // The command prop still exists on navData - it's currently not used. 
        setURL(route);
    }, [window.location.search])
    
    const onLinkClick = (event) => {
        event.preventDefault();
        if (navData.custom) {
            const customRoute = '/' + 
                (navData?.root ? navData.root + '/' : '') +  
                (navData?.useId ? id + '/' : '') +  
                (navData?.ending ? navData.ending + '/' : '') +  
                (navData?.useType ? rowData[navData.useType] : '');
            navTo(customRoute);
        } else {
            route && navTo(route);
        }
    }

    return (
        <div className={`link-container`}>
            {loading ? <div className='link-spinner spinner'/>
            :<a  href={URL} 
                className={`link-target`} 
                onClick={(event) =>{onLinkClick(event)}}
            >{type === 'icon' ? <Icon icon={children}/> :children}</a>}
        </div>
    )
}

export default Link;