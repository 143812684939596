import './App.scss';
import { useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import Header from 'app-components/Header/Header';
import MainMenu from 'app-components/MainMenu/MainMenu';
import Tooltip from 'components/Tooltip/Tooltip';
import PermissionsCheck from 'systems/PermissionsCheck';
import Companies from './pages/Companies/Companies/Companies';
import BatchAssignment from 'pages/Devices/BatchAssignment/BatchAssignment';
import CompanyDetails from './pages/Companies/CompanyDetails/CompanyDetails';
import CompanyUsers from 'pages/Companies/CompanyUsers/CompanyUsers';
import CompanySettings from 'pages/Companies/CompanySettings/CompanySettings';
import CompanyServices from 'pages/Companies/CompanyServices/CompanyServices';
import CompanyLocations from 'pages/Companies/CompanyLocations/CompanyLocations';
import CompanyDevices from 'pages/Companies/CompanyDevices/CompanyDevices';
import DeleteAccounts from 'pages/DeleteAccounts/DeleteAccounts';
import Devices from 'pages/Devices/Devices/Devices';
import DevicesPrimePays from 'pages/Devices/DevicesPrimePays/DevicesPrimePays';
import DevicesGRCKiosks from 'pages/Devices/DevicesGRCKiosks/DevicesGRCKiosks';
import DevicesGameroomPOS from 'pages/Devices/DevicesGameroomPOS/DevicesGameroomPOS';
import DevicesGRCPOS from 'pages/Devices/DevicesGRCPOS/DevicesGRCPOS';
import DevicesGames from 'pages/Devices/DevicesGames/DevicesGames';
import DevicesMonitors from 'pages/Devices/DevicesMonitors/DevicesMonitors';
import DevicesBingo from 'pages/Devices/DevicesBingo/DevicesBingo';
import DeviceDetails from './pages/Devices/DeviceDetails/DeviceDetails';
import DeviceControls from 'pages/Devices/DeviceControls/DeviceControls';
import DeviceReports from 'pages/Devices/DeviceReports/DeviceReports';
import DevicePullTabs from 'pages/Devices/DevicePullTabs/DevicePullTabs';
import Error from 'pages/Error/Error';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Locations from './pages/Locations/Locations/Locations';
import LocationDetails from './pages/Locations/LocationDetails/LocationDetails';
import LocationDevices from './pages/Locations/LocationDevices/LocationDevices';
import LocationReports from 'pages/Locations/LocationReports/LocationReports/LocationReports';
import LocationSettings from 'pages/Locations/LocationSettings/LocationSettings';
import LocationServices from 'pages/Locations/LocationServices/LocationServices';
import LocationPlayers from 'pages/Locations/LocationPlayers/LocationPlayers';
import LocationPlayerHistory from 'pages/Locations/LocationPlayerHistory/LocationPlayerHistory';
import Login from './pages/Login/Login';
import Manifests from 'pages/Manifests/Manifests/Manifests';
import ManifestDetails from 'pages/Manifests/ManifestDetails/ManifestDetails';
import Permissions from 'pages/Permissions/Permissions';
import PermissionsUsers from 'pages/Permissions/PermissionsUsers/PermissionsUsers';
import Sources from 'pages/Sources/Sources/Sources';
import SourceDetails from 'pages/Sources/SourceDetails/SourceDetails';
import Reports from './pages/Reports/Reports/Reports';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Users from './pages/Users/Users/Users';
import UserDetails from './pages/Users/UserDetails/UserDetails';
import UserPassword from 'pages/Users/UserPassword/UserPassword';
import UserPermissions from 'pages/Users/UserPermissions/UserPermissions';

const App = () => {
  const { size, setSize, 
          openMainMenu, openPageMenu,
          headerMessage, setHeaderMessage } = useProfile();
  const [ showCopied, setShowCopied ] = useState(false);
  const [ clicked, setClicked ] = useState();
  const [ showMessage, setShowMessage ] = useState(false);
  const [ success, setSuccess ] = useState(true);
  const selectedField = useRef();
  const phoneBreakpoint = 450 // value of $breakpoint-phone-width from core.scss.

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.keyCode;
      if (event.ctrlKey && key === 67 && clicked) {
        controlCopy();
      } else if (key === 40 || key === 38) {
        event.preventDefault();
        onArrowNav(key);
      } else if (key === 13) {
        onEnter(event);
      }
    }
    updatePhoneSize();
    window.addEventListener('resize', updatePhoneSize);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('resize', updatePhoneSize);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(()=> {
    if (headerMessage) {
        setShowMessage(true);
        headerMessage === t.updateComplete ? setSuccess(true) : setSuccess(false);
        const duration = headerMessage.length > 20 ? 5000 : 2000;
        setTimeout(() => {
            setShowMessage(false);
            setTimeout(() => {
                setHeaderMessage('');
            }, 500);
        }, duration);
    }
}, [headerMessage])

  const updatePhoneSize = () => {
    if (window.innerWidth <= phoneBreakpoint) {
      setSize('phone');
    } else if (window.innerHeight <= phoneBreakpoint) {
      setSize('phone-landscape')
    } else {
      setSize('default')
    }
  }

  const onArrowNav = (key) => {
    const field = selectedField.current;
    const el = document.activeElement;
    const dropdown = document.getElementById(field + '-options');
    const classes = el.classList;
    let target;
    if (classes.contains('input-display')) {
      target = dropdown?.children[0];
    } else if (classes.contains('dropdown-input-option')) {
      if (el.dataset.index === '0' && key === 38) {
        target = document.getElementById(field);
      } else {
        key === 40 ? target = el.nextElementSibling : target = el.previousElementSibling
      }
    }
    if (target) {
      target.focus();
    }
  }

  const onEnter = (event) => {
    const el = document.activeElement;
    const classes = el.classList;
    if (classes.contains('dropdown-input-option') || classes.contains('input-display') || classes.contains('standard-button')) {
      event.preventDefault();
      el.click();
    }
  }

  const controlCopy = () => {
    navigator.clipboard.writeText(clicked.content);
    const message = document.getElementById('copied-message');
    const cell = document.getElementById(clicked.id).getBoundingClientRect();
    message.style.top = cell.top + 'px';
    message.style.left = cell.left + 'px';
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
      message.style.top = '0px';
      message.style.left = '0px';
    }, 550);
  }

  const onPageClick = (event) => {
    const target = event.target;
    const classList = target.classList;
    checkForCell(target, classList);
    if (size !== 'phone') {return};
    if (!target.closest('.main-menu-toggle-container')) {openMainMenu(false)};
    if (!target.closest('.page-menu-container')) {openPageMenu(false)};
  }

  const checkForCell = (target, classes) =>{
    if (classes.contains('table-cell-content')) {
      const clickData = {
        id: target.parentElement.id,
        content: target.textContent
      }
      setClicked(clickData);
    }
  }

  const routes = [
    { path: '/companies', element: <Companies/> },
    { path: '/company/:id', element: <CompanyDetails/>},
    { path: '/company/:id/users', element: <CompanyUsers/>},
    { path: '/company/:id/settings', element: <CompanySettings/>},
    { path: '/company/:id/services', element: <CompanyServices/>},
    { path: '/company/:id/locations', element: <CompanyLocations/>},
    { path: '/company/:id/devices', element: <CompanyDevices/>},
    { path: '/delete-accounts', element: <DeleteAccounts/>},
    { path: '/devices', element: <Devices/>},
    { path: '/devices/primepay', element: <DevicesPrimePays/>},
    { path: '/devices/grc-kiosks', element: <DevicesGRCKiosks/>},
    { path: '/devices/gameroom-pos', element: <DevicesGameroomPOS/>},
    { path: '/devices/grc-pos', element: <DevicesGRCPOS/>},
    { path: '/devices/games', element: <DevicesGames/>},
    { path: '/devices/monitors', element: <DevicesMonitors/>},
    { path: '/devices/batch-assignment', element: <BatchAssignment/>},
    { path: '/devices/bingo', element: <DevicesBingo/>},
    { path: '/device/:id', element: <DeviceDetails/>},
    { path: '/device/:id/controls', element: <DeviceControls/>},
    { path: '/device/:id/reports', element: <DeviceReports/>},
    { path: '/device/:id/reports/:type', element: <DeviceReports/>},
    { path: '/device/:id/pull-tabs', element: <DevicePullTabs/>},
    { path: '/locations', element: <Locations/>},
    { path: '/location/:id', element: <LocationDetails/>},
    { path: '/location/:id/devices', element: <LocationDevices/>},
    { path: '/location/:id/reports', element: <LocationReports/>},
    { path: '/location/:id/reports/:type', element: <LocationReports/>},
    { path: '/location/:id/settings', element: <LocationSettings/>},
    { path: '/location/:id/services', element: <LocationServices/>},
    { path: '/location/:id/players', element: <LocationPlayers/>},
    { path: '/location/:id/player/:type', element:<LocationPlayerHistory/>},
    { path: '/manifests', element: <Manifests/>},
    { path: '/manifest/:id', element: <ManifestDetails/>},
    { path: '/permissions', element: <Permissions/>},
    { path: '/permissions/users', element: <PermissionsUsers/>},
    { path: '/reports', element: <Reports/>},
    { path: '/reports/:type', element: <Reports/>},
    { path: '/sources', element: <Sources/>},
    { path: '/source/:id', element: <SourceDetails/>},
    { path: '/users', element: <Users/>},
    { path: '/user/:id', element: <UserDetails/>},
    { path: '/user/:id/password', element: <UserPassword/>},
    { path: '/user/:id/permissions', element: <UserPermissions/>}
  ]

  const createPermissionRoutes = (routes) => {
    return routes.map((route, i) => (
      <Route key={i} path={route.path} element={
        <PermissionsCheck path={route.path}>
          {route.element}
        </PermissionsCheck>
      }/>
    ));
  }

  return (
    <div id='App' className='App' onClick={(event) => { onPageClick(event) }}>
      <div id='modal'/>
      <div id='update-notice' className={`header-message ${showMessage ? 'show' : 'hide'} ${success ? 'success' : 'fail'}`}>
          {headerMessage}
      </div>
      <MainMenu/>
      <div id='content'>
        <Tooltip/>
        {<div id='copied-message' className= {showCopied ? 'show' : ''}>{t.copied}</div>}
        <Header/>
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route path='/forgot-password' element={<ForgotPassword/>}/>
          <Route path='/reset-password' element={<ResetPassword/>}/>
          {createPermissionRoutes(routes)}
          <Route exact path='/' element={<Navigate to='/devices'/>}/>
          <Route exact path='/*' element={<Error/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;
