import './users.scss';
import { useEffect, useRef, useState } from 'react';
import { usersLayout as layout } from './UsersLayout';
import useAuth from 'hooks/Auth/useAuth';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const Users = () => {
    const { getAuth } = useAuth();
    const { callAPI, hasRank } = useUtils();
    const { refreshPage, setRefreshPage } = useProfile();
    const [ users, setUsers ] = useState();
    const [ tableLoading, setTableLoading ] = useState([true]);
    const permitted = hasRank('owner');
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('users', usersCallback, permitted ? {} : {companyId: getAuth().companyId});
    }, [])

    useEffect(() => {
        refreshPage[0] && callAPI('users', usersCallback,  permitted ? {} : {companyId: getAuth().companyId});
    }, [refreshPage])

    const usersCallback = (data) => {
        setUsers(data?.webPortalUserList ? data.webPortalUserList : []);
        setTableLoading([false]);
        setRefreshPage([false]);
    }

    return (
        <Page page={layout.page}>
            <Table
                data={users}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default Users;
