import '../Devices/devices.scss';
import { useEffect, useRef, useState } from 'react';
import { devicesPrimePayLayout as layout } from './DevicesPrimePayLayout';
import { filterDevicesByValue, } from 'shared/utils';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const DevicesPrimePays = () => {
    const { devicesTabs } = useProfile();
    const { callAPI, updateDevicesTabs } = useUtils();
    const [ tableData, setTableData ] = useState();
    const [ tableLoading, setTableLoading ] = useState([true]);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('devices', devicesListCallback);
        updateDevicesTabs();
    }, [])

    const devicesListCallback = (data) => {
        setTableLoading([false]);
        const list = data?.list ? filterDevicesByValue(data.list, 1 ) : []; // 1 for Prime Pay
        setTableData(list)
    }

    return (
        <Page
            page={layout.page}
            subPage={layout.subpage}
            tabs={devicesTabs}
        >
            <Table
                data={tableData}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default DevicesPrimePays;
