import './locationReports.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
import LocationTransactions from '../LocationTransactions/LocationTransactions';
import LocationAudit from '../LocationAudit/LocationAudit';
import LocationGameroom from '../LocationGameroom/LocationGameroom';
import LocationKiosk from '../LocationKiosk/LocationKiosk';
import LocationPlayerInfo from '../LocationPlayerInfo/LocationPlayerInfo';

const locationReports = {
    transactions: <LocationTransactions/>,
    audit: <LocationAudit/>,
    kiosk: <LocationKiosk/>,
    gameroom: <LocationGameroom/>,
    playerInfo: <LocationPlayerInfo/>,
}

const LocationReports = () => {
    const { id, type } = useParams();
    const { navTo } = useComponents();
    const { updateDropdown } = useInputs();
    const { locationTabs, updateLocationHeader } = useProfile();
    const { callAPI, updateLocationTabs } = useUtils();
    const [ reportsList, setReportsList ] = useState();
    const [ currentReport, setCurrentReport ] = useState();

    useEffect(() => {   
        if (!currentReport && type) {
            updateDropdown('location-reports', type, getDropdownLabel(type));
        }
    }, [])

    useEffect(() => {
        const params = {id: id}
        callAPI('locationDetails', locationDetailsCallback, params);
        updateDropdown('location-reports', type, getDropdownLabel(type));
        setCurrentReport(type);
    }, [type])

    useEffect(() => {
        if (currentReport && type !== currentReport) {
            navTo(`/location/${id}/reports/${currentReport}`);
        }
    }, [currentReport]);

    const locationDetailsCallback = (data) => {
        const types = data?.kioskTypes;
        updateLocationTabs(types); 
        setReportsList(getReportsList(types))
        updateLocationHeader(data?.locationDetails);
    }

    const getReportsList = (array) => {
        if (!array || array.length === 0) {return []}
        const hasGRC = array.includes(2);
        const hasPiggyBank = array.includes(3);
        const hasPOS = array.includes(4);
        const hasBingo = array.includes(7);
        const reportsList = [
            {value: 'transactions', label: t.transactions},
            {value: 'audit', label: t.audit},
        ]
        hasPOS && reportsList.push({value: 'gameroom', label: t.gameroom});
        if (hasPiggyBank || hasGRC || hasBingo) {
            reportsList.push({value: 'kiosk', label: t.kiosk})
        };
        (hasPOS || hasGRC) && reportsList.push({value: 'playerInfo', label: t.playerInfo})
        return reportsList;
    }

    const onReportSelect = (data) => {
        setCurrentReport(data.value)
    }

    const getDropdownLabel = (val) => {
        switch(val) {
            case 'audit':
                return t.audit;
            case 'gameroom':
                return t.gameroom;
            case 'kiosk':
                return t.kiosk;
            case 'playerInfo':
                return t.playerInfo
            default:
                return t.transactions;
        }
    }

    return (
        <Page
            subPage={t.reports}
            tabs={locationTabs.tabs}
        >
            <Card hideLabel={true} classes='grid margin-bottom'>
                <Dropdown
                    id='location-reports'
                    classes='quarter'
                    label={t.selectedReport}
                    noPermission={reportsList?.length <= 1}
                    hideErrors={true}
                    data={reportsList}
                    callback={onReportSelect}/>
            </Card>
            {locationReports[currentReport]}
        </Page>
    )
}

export default LocationReports;
