import './tableCell.scss';
import { deepCopy, isValid, toCurrency } from 'components/shared/componentUtils';
import { tablesData as t } from '../../tablesData';
import { addCellClasses, formatContent, showNullVal } from '../../tableUtils';
import useComponents from 'components/hooks/Components/useComponents';
import useTables from '../TableHooks/useTables';
import Link from 'components/Link/Link';

const TableCell = ({id, layout, prop, index, content, appendData, rowId, tooltip, callback}) => {
    const { changeTooltipData } = useComponents();
    const { tableData, tableCheckAlls, 
            tableSelectedRows, tableChecks, 
            updateTable } = useTables();
    const cellId = rowId + '-' + prop;

    const handleMouseEnter = (event) => {
        tooltip && changeTooltipData({
            id: cellId,
            type: 'cell',
            content: content 
        });
    };

    const handleMouseLeave = (event) => {      
        if (!event.relatedTarget.id?.includes('tooltip')) {
            changeTooltipData(null);
        }
    };

    const onClick = (event) => {
        const target = event.target;
        const classList = target.classList;
        const classesToCheck = ['checkmark', 'table-multi-check', 'cell-tooltip'];
        if (classesToCheck.some(className => classList.contains(className))) {return};
        callback && callback(prop, cellId, tableData[id][index]);
        if (!(event.target.tagName === 'A')) {
            const rowId = target.closest('.table-row').id;
            updateTable('selectedRows', id, tableSelectedRows?.[id] === rowId ? null : rowId);
        }
    }
    
    const buildLink = (prop) => {
        if (!tableData) {return};
        const navData = layout?.navigation?.[prop];
        const value = navData.value;
        const ending = navData.ending;
        const rowData = tableData[id][index];
        const targetIdVal = rowData[value]
        const contentVal = rowData[navData.label];
        const route = ending ? `${targetIdVal}/${ending}` : rowData[value];
        const content = formatContent(contentVal, prop, layout);
        if (content === t.nullVal) {
            return content;
        } else {
            return <Link route={`/${navData.root}/${route}`} navData={navData} rowData={rowData} targetIdVal={targetIdVal}>
                {content}
            </Link>
        }
    }

    const onMultiCheckClick = (target) => {
        const rowId = target.dataset.rowid;
        const checksArray = deepCopy(tableChecks?.[id] ? tableChecks[id] : []);
        if (checksArray.includes(rowId)) {
            const newArray = checksArray.filter((string) => string !== rowId);
            updateTable('checks', id, newArray);
        } else {
            checksArray.push(rowId);
            updateTable('checks', id, checksArray);
        }
        updateTable('checkAlls', id, false);
    }

    const getCellContents = ()=> {
        if (layout.navigation?.[prop] && isValid(content) && (content !== 0 || layout.navigation?.[prop]['label'] === 'companyId')) {
            return buildLink(prop);
        }
        switch (prop) {
            case 'checkbox':
                const checked = tableCheckAlls?.[id] || tableChecks?.[id]?.includes(rowId) ? true : false;                
                return (
                    <label className='table-checkbox'>
                      <input
                        id={`table-multi-check-${cellId}`}
                        className='table-multi-check'
                        checked={checked}
                        data-rowid={rowId}
                        type='checkbox'
                        onChange={(event) => {onMultiCheckClick(event.currentTarget)}}
                      />
                      <span className='checkmark'></span>
                    </label>
                  );
            case 'cassettes':
            case 'stacker':
                return showNullVal(content) ? t.nullVal : content.currentCount + '/' + content.maxCount + ': ' + toCurrency(content.billValue ? content.billValue : 0);
            default:
                if (appendData) {
                    return formatContent(content, prop, layout) + ' (' + formatContent(appendData.content, appendData.prop, layout) + ')';
                } else {
                    return formatContent(content, prop, layout);
                }
        }
    }
    const cellContent = getCellContents();
    
    return (
        <div 
            id={cellId} 
            className={`table-cell ${addCellClasses(prop, layout, cellContent)}`}
            onMouseEnter={(event) => handleMouseEnter(event)}
            onMouseLeave={(event) => handleMouseLeave(event)}
            onClick={(event) => onClick(event)}
        >
            <div id={id+'-content'}className={`table-cell-content`}>{cellContent}</div>
        </div>
    )
}

export default TableCell;
