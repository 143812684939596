import { addCellClasses } from 'app-components/Table/tableUtils';
import useProfile from 'hooks/Profile/useProfile';

const TableCell = ({layout, prop, children, index, tooltipInfo}) => {
    const { setTooltipData } = useProfile();
    const hasTooltip = layout.tooltips?.[prop];
    const id = layout.id + '-table-cell-' + prop + '-' + index;

    const handleMouseEnter = (event) => {
        if (!tooltipInfo) {return};
        const currentId = event.currentTarget.id;
        hasTooltip && setTooltipData({
            id: currentId,
            content: tooltipInfo.content
        });
    };

    const handleMouseLeave = (event) => {        
        if (!event.relatedTarget.id?.includes('tooltip')) {
            setTooltipData(null);
        }
    };
    
    return (
        <div
            id={id}
            className={`table-cell ${addCellClasses(prop, layout, children, null)}`}
            onMouseEnter={(event) => handleMouseEnter(event)}
            onMouseLeave={(event) => handleMouseLeave(event)}
        >
            <div id={id+'-content'}className={`table-cell-content`}>{children}</div>
        </div>
    )
}

export default TableCell;
