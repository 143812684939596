import './serviceItem.scss';
import { useEffect, useRef } from 'react';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useUtils from 'hooks/useUtils';
import Input from 'components/Input/Input';
import Checkbox from 'components/Checkbox/Checkbox';
import primepay from 'shared/img/services/primepay.png';
import goldrushcity from 'shared/img/services/goldrushcity.png';
import piggybank from 'shared/img/services/piggybankdeluxe.png';
import bingo from 'shared/img/services/bingo.png';
import gameroomPOS from 'shared/img/services/gameroomPOS.png';

const ServiceItem = ({ prop, classes, masked, callback}) => {
    const { inputValues, inputErrors } = useInputs();
    const { hasRank } = useUtils();
    const permitted = hasRank('owner', true);
    const isPrimero = hasRank('superuser', true);
    const isActive = useRef();

    useEffect(() => {
        isActive.current = inputValues[prop + '-active']
    }, [inputValues])

    const images = {
        primePay: primepay,
        sweepsKiosk: goldrushcity,
        connectedGames: piggybank,
        bingoKiosk: bingo,
        gameRoomPOS: gameroomPOS,
    };

    const labels = {
        primePay: t.primePayLabel,
        sweepsKiosk: t.goldRushCityKiosk,
        bingoKiosk: t.bingoKiosk,
        connectedGames: t.connectedGames,
        gameRoomPOS: t.gameroomPOS
    }

    return (
        <div className={`service-item ${classes ? classes : ''}`}>
            <div className={`service-item-contents ${(!isActive.current && !isPrimero) ? 'disabled' : ''}`}>
                <div className='service-item-label'>{labels[prop]}</div>
                <div className='service-item-logo-container'>
                    <img className='service-item-logo' src={images[prop]} alt='logo'/>
                </div>
                <div className='service-item-input-container grid'>
                    <Checkbox label={t.enabled} section={prop} line='active' classes={`half right centered ${!isPrimero ? 'display-only' : ''}`} />
                    {prop !== 'primePay' && <Input classes='two-thirds centered' section={prop} line='licenseRate' masked={masked} type='percentage'
                        label={t.licenseRate} noPermission={!isPrimero} hideErrors={true} callback={callback} disabled={!isActive.current}/>}
                    {prop !== 'primePay' && <Input classes='two-thirds centered' section={prop} line='operatorRate' masked={masked} type='percentage'
                        label={t.operatorRate} noPermission={!permitted} hideErrors={true}callback={callback} disabled={!isActive.current}/>}
                    {prop !== 'primePay' && <Input classes='two-thirds centered' section={prop} line='locationRate' masked={masked} type='percentage'
                        label={t.locationRate} noPermission={!permitted} hideErrors={true} callback={callback} disabled={!isActive.current}/>}
                    <div className='two-thirds'></div><div className='service-item-error full'>{inputErrors?.[prop + '-operatorRate']}</div>
                </div>
            </div>
            {(!isActive.current && !isPrimero) && <div className='service-item-banner'>
                <span className='service-item-banner-text'>{t.serviceItemAd}</span>
            </div>} 
        </div>
    )
}

export default ServiceItem;