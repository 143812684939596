import { text as t } from 'shared/text';

export const companyTabsList = [
    { label: t.details, path: '/company/:id', rank: 'all'},
    { label: t.users, path: '/company/:id/users', rank: 'all'},
    { label: t.locations, path: '/company/:id/locations', rank: 'all'},
    { label: t.settings, path: '/company/:id/settings', permission: '/company/:id', rank: 'owner'},
    { label: t.services, path: '/company/:id/services', permission: '/company/:id', rank: 'owner'},
]

export const deviceTabsList = [
    { label: t.details, path: '/device/:id', rank: 'all'},
    { label: t.controls, path: '/device/:id/controls', rank: 'owner'},
    { label: t.reports, path: '/device/:id/reports/transactions',  rank: 'all'},
    { label: t.pullTabs, path: '/device/:id/pull-tabs', rank: 'admin'}
]

export const devicesTabsList = {
    all: { label: t.all, path: '/devices', rank: 'all'},
    primePay: { label: t.primePay, path: '/devices/primepay', rank: 'all'},
    sweepsKiosk: { label: t.grcKiosks, path: '/devices/grc-kiosks', rank: 'all'},
    gameRoomPOS: { label: t.gameroomPOS, path: '/devices/gameroom-pos', rank: 'all'},
    grcPointOfSale: { label: t.grcPOS, path: '/devices/grc-pos', rank: 'all'},
    connectedGames: { label: t.games, path: '/devices/games', rank: 'all'},
    bingoKiosk: { label: t.bingo, path: '/devices/bingo', rank: 'all'},
    monitor: { label: t.monitors, path: '/devices/monitors', rank: 'admin'}
}

export const locationTabsList =  [
    { label: t.details, path: '/location/:id', rank: 'all'},
    { label: t.devices, path: '/location/:id/devices', rank: 'all'},
    { label: t.reports, path: '/location/:id/reports/transactions', rank: 'all'},
    { label: t.settings, path: '/location/:id/settings', rank: 'all'},
    { label: t.services, path: '/location/:id/services', rank: 'all'},
    { label: t.players, path: '/location/:id/players', rank: 'all'}
]

export const permissionTabsList = [
    { label: t.roles, path: '/permissions', rank: 'owner'},
    { label: t.users, path: '/permissions/users', rank: 'owner'},
]

export const userTabsList = [
    { label: t.details, path: '/user/:id', rank: 'all'},
    { label: t.password, path: '/user/:id/password', rank: 'owner'},
    { label: t.permissions, path: '/user/:id/permissions', rank: 'all'}
]