import './rowCountPicker.scss';
import { useEffect, useState } from 'react';
import { deepCopy } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';

const RowCountPicker = ({layout, data}) => {
    const { tableStates, setTableStates } = useProfile();
    const [ currentCount, setCurrentCount ] = useState();
    const [ displayOpen, setDisplayOpen] = useState(false);
    const [ mainMenuOpen, setMainMenuOpen ] = useState(false); 
    const [ menuBorder, setMenuBorder ] = useState(false);
    const id = layout.id;

    useEffect(() =>{
        if (!tableStates) return;
        if (tableStates[id]?.rowCount) {
            setCurrentCount(tableStates[id].rowCount); 
        } else if (layout.rowCount) {
            setCurrentCount(layout.rowCount);
        } else {
            setCurrentCount(t.defaultRowCount);
        }
    }, [tableStates[id]])

    const buildOption = (value, i) => {
        return <div key={i} className='row-count-option' data-value={value} onClick={(event) => onSelect(event.currentTarget)}>{value}</div>
    }

    const onSelect = (target) => {
        const val = target.innerHTML;
        setCurrentCount(val);
        const newStates = deepCopy(tableStates);
        if (!newStates[id]) {newStates[id]={}};
        newStates[id].rowCount = val;
        newStates[id].currentPage = [1];
        newStates[id].pageCount = data.length ? Math.ceil(data.length/val) : 1;
        setTableStates(newStates);
    };

    const toggleDropdown = () => {
        if (!displayOpen) {
            setDisplayOpen(true);
            setTimeout(() => {
                setMenuBorder(true);
                setMainMenuOpen(true);
            }, 25)
        } else {
            closeDropdown();
        }
    } 

    const onExit = () => {
        closeDropdown();
    }
    
    const closeDropdown = () =>{
        setMainMenuOpen(false);
        setTimeout(() => {
            setMenuBorder(false);
            setDisplayOpen(false);
        }, 150)
    }

    return (
        <div  className='row-count-picker'>
            <label className='label-first'>{t.show}</label>
            <div className={`row-count-container`} onClick={toggleDropdown} onMouseLeave={(event)=>{onExit(event)}}>
                <div className={`row-count-display ${displayOpen ? 'open' : ''}`}>
                    {currentCount}
                </div>
                <div className={`row-count-menu ${mainMenuOpen ? 'open' : ''} ${menuBorder ? 'border' : ''}`}> 
                    {t.rowCounts.map((item, i)=>{
                        return buildOption(item, i)
                    })}
                </div>
            </div>
            <label className='label-last'>{t.entriesNoCap}</label>
        </div>
    )   
};

export default RowCountPicker;