import './dropdown.scss';

import { useEffect, useRef, useState } from 'react';
import { deepCopy, getObjectLabel, getFormId,
         isValid, sortObjectArrayAsc } from 'components/shared/componentUtils';
import { componentText as t } from 'components/shared/componentData';
import useInputs from '../hooks/Inputs/useInputs';
import DropdownOption from './DropdownOption/DropdownOption';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';

const Dropdown = ({ id, classes,
                    section, line, 
                    index, hideErrors,
                    type, placeholder, 
                    startFocused, masked, 
                    maxLength, disabled,
                    label, hideLabel,
                    valueProp = 'id', 
                    labelProp = 'name', 
                    appendProp, data, 
                    updatesNext, nullable, 
                    noPermission, addCallback, 
                    callback, onBlurCallback }) => {
    const { currentInput, inputValues,
            dropdownDisplays, dropdownOriginals, 
            updateDropdown, updateDropdownDisplays,
            changeCurrentInput, updateInputErrors,
            clearError } = useInputs();
    const [ options, setOptions ] = useState();
    const [ displayedOptions, setDisplayedOptions ] = useState();
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const [ mask, setMask ] = useState(masked);
    const [ restoreOriginal, setRestoreOriginal ] = useState(false);
    const [ showAdd, setShowAdd ] = useState(false);
    id = line ? getFormId(section, line, index) : id;
    const original = useRef('');
    const currentInputRef = useRef(currentInput);
    const focusing = useRef();
    const closing = useRef();
    useEffect(() => {
        changeCurrentInput('');
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    useEffect(() => {
        const list = !data?.length > 0 ? [{type: 'placeholder', label: t.noResults}] : updateOptionsList(deepCopy(data));
        setOptions(list);
    }, [data]);

    useEffect(() => {
        if (!isValid(displayedOptions) || restoreOriginal || disabled) {
            setRestoreOriginal(false);
            return
        };
        if (!isValid(displayedOptions, nullable)) {
            setMask(true);
            return
        } else if (!masked) {
            setMask(false);
        }
    }, [displayedOptions]);

    useEffect(() => {
        if (!options) {return}
        let val = dropdownDisplays[id];
        if (!isValid(val) && !nullable) {
            updateOptionsList(options);
            return;
        }
        if (val && val.length > 0) {
            val = val.toString().toUpperCase();
            const newOptions = [];
            options.forEach((item)=>{
                let label = getObjectLabel(item, labelProp, appendProp).toUpperCase();
                (label && label.includes(val)) && newOptions.push(item);
            })
            if (newOptions.length === 0) { newOptions.push({type: 'placeholder', label: t.noResults}) };
            updateOptionsList(newOptions);
        } else {
            updateOptionsList(options);
        }
    }, [dropdownDisplays[id]])

    useEffect(() => {
        if (!restoreOriginal) {return};
        updateDropdownDisplays(id, original.current)
    }, [restoreOriginal]);

    useEffect(()=>{
        if (original.current !== dropdownOriginals[id]) {
            original.current = dropdownOriginals[id];
        }
    }, [dropdownOriginals[id]])

    useEffect(() => {
        setMask(masked);
    }, [masked]);
    
    // Mouse & Keyboard Interactions
    const onKeyDown = (event) => {
        const key = event.keyCode;
        if (currentInputRef.current === id) {
            if (key === 40 || key === 38) {
                event.preventDefault();
                onArrowNav(key);
            } else if (key === 13 || key === 9) {
                const controlled = (key === 9 && (updatesNext || event.shiftKey)) ? true : false; 
                const currentList = document.getElementById(id + '-options').children;
                if (currentList.length === 1) {
                    currentList[0].click();
                } else if (event.target.classList.contains('dropdown-option')) {
                    onEnter(event, key, controlled);
                }
            }
        }
    }

    const onArrowNav = (key) => {
        const field = currentInputRef.current;
        const el = document.activeElement;
        const classes = el.classList;
        let target;
        if (classes.contains('input-display')) {
            const dropdown = document.getElementById(field + '-options');
            target = dropdown?.children[0];
        } else if (classes.contains('dropdown-option')) {
            if (el.dataset.index === '0' && key === 38) {
                target = document.getElementById(field);
            } else {
                key === 40 ? target = el.nextElementSibling : target = el.previousElementSibling
            }
        }
        if (target) {
            target.focus();
        }
    }

    // Open & Closing Animation Functions
    const onFocus = () => {
        if (masked || noPermission || disabled) {return}
        focusing.current = true;
        setTimeout(() => {focusing.current = false}, 250)
        changeCurrentInput(id);
        clearError(id);
        addCallback && setShowAdd(true);
        const classes = document.activeElement.classList;
        currentInputRef.current = id;
        if (classes.contains('dropdown-option')) {return};
        const el = document.getElementById(id);
        if (el && !disabled) {
            changeCurrentInput(id);
            setDisplayedOptions(options);
            if (!options || options.length === 0 || disabled) {return}
            if (!dropdownOpen) {
                setDropdownOpen(true);
            } 
        }
    }

    const onToggleClick = () => {
        if (!focusing.current && !masked && !noPermission && !disabled) {
            if (document.getElementById(id).closest('.dropdown-container').dataset.open === 'true') {
                closeDropdown();
            } else {
                setDropdownOpen(true)
            }
        }
    }

    const onSelect = ( target ) => {
        if (!displayedOptions) { return }
        closeDropdown();
        const rawVal = target.dataset.value;
        const val = !isValid(rawVal) ? null :isNaN(rawVal) ? rawVal : parseInt(rawVal);
        const label = target.textContent;
        updateDropdown(id, val, label);
        original.current = label;
        const update = { id: id, value: val, label: label, originalVal: inputValues[id], originalLabel: dropdownDisplays[id], index: target.dataset.index};
        if (section) { update.section = section };
        if (line) { update.line = line };
        if (type) { update.type = type };
        callback && callback(update);
    }

    const onEnter = (event, key, controlled) => {
        const el = event.target;
        const classes = el.classList;
        if (classes.contains('dropdown-option')) {
          if (key === 13) {event.preventDefault()};
          if (controlled) {
            event.preventDefault();
          };
          el.click();
        }
    }

    const onAddClick = () => {
        const text = dropdownDisplays?.[id];
        let exists;
        for(let i=0; i<options.length; i++) {
            if (options[i][labelProp ? labelProp : 'label'] === text) {
                exists = true;
                break;
            }
        }
        exists ? updateInputErrors(id, t.errorOptionExists) : addCallback(text);
    }

    const onBlur = () => {
        setTimeout(() => {
            const el = document.activeElement
            const classes = el.classList;
            if (classes.contains('dropdown-option') || (classes.contains('input-display') && el.id === currentInputRef)) {return};
            if (el.id === currentInput && classes.contains('input-display')) {return}
            currentInputRef.current = null;    
            closeDropdown()
        }, 0)
    }

    const onDropdownAutofill = (label) => {
        label = label.toString();
        const testLabel = label.toUpperCase();
        let val;
        const length = data.length;
        for (let i=0; i<length; i++) {
            const option = data[i];
            if (option[labelProp]?.toUpperCase() === testLabel) {
                val = option[valueProp]
                break;
            }
        }
        val && updateDropdown(id, val, label);
        closeDropdown();
    }

    // Helper Functions
    const closeDropdown = () => {
        closing.current = true; 
        setTimeout(() => {closing.current = false}, 50)
        setTimeout(() => {
            setRestoreOriginal(true); // this is needed to avoid stale data inside a timeout.
            setDropdownOpen(false);
            setShowAdd(false)
            const el = document.getElementById(id);
            el && el.blur();
            onBlurCallback && onBlurCallback([]);
        }, 0);
    }

    const updateOptionsList = (data) => {
        if (closing.current) {return}
        const sorted = sortObjectArrayAsc(data);
        setDisplayedOptions(sorted);
        return sorted;
    }

    const getNullableLabel = () => {
        return (typeof nullable === 'string') ? nullable : t.all;
    }

    return (
        <div id={`${id}-dropdown`}
            className={[
                'dropdown-container',
                classes || '',
                hideLabel ? 'hide-label' : '',
                hideErrors ? 'hide-errors' : '',
                disabled ? 'disabled' : '',
                noPermission ? 'display-only' : ''
            ].join(' ')}
            disabled={disabled}
            data-open={dropdownOpen}
            data-id={id}
            onBlur={onBlur}
            onFocus={()=>{onFocus()}}
            onClick={onToggleClick}
        >
            <div className='dropdown-display-container'>
                <Input
                    id={id}
                    classes={`dropdown-display ${dropdownOpen ? 'open' : ''} ${placeholder ? 'placeholder' : ''}`}
                    section={section}
                    line={line}
                    index={index}
                    type={type}
                    placeholder={placeholder ? placeholder : (options && options.length === 0) ? t.noResults : t.noSelection}
                    label={label}
                    hideLabel={hideLabel}
                    disabled={disabled}
                    startFocused={startFocused}
                    hideErrors={hideErrors}
                    masked={mask}
                    maxLength={maxLength}
                    dropdown={true}
                    onDropdownAutofill={onDropdownAutofill}
                    selectOnClick={true}
                    noPermission={noPermission}
                    blockBlur={true}
                />
                <div id={id + '-options'} className={`dropdown-menu ${dropdownOpen ? 'open border' : ''}`}>
                    {nullable  && <DropdownOption 
                            item={{value: null, label: getNullableLabel(nullable)}}
                            labelProp={labelProp} 
                            callback={onSelect}/>
                    }
                    {displayedOptions ? displayedOptions.map(( item, i ) => {
                        return <DropdownOption 
                            key={item.key ? item.key : i} 
                            i={i}
                            item={item} 
                            valueProp={valueProp} 
                            labelProp={labelProp} 
                            appendProp={appendProp}
                            callback={onSelect}/>
                    }) : ''}
                </div>
            </div>
            <div 
                className={`dropdown-arrow ${dropdownOpen ? 'open' : ''}`}
                data-dropdown={true}
            >{<Icon icon='caret-down'/>}
            </div>
            {showAdd && <div 
                className={`add-icon`}
                data-dropdown={true}
                onClick={onAddClick}
                >{<Icon icon='plus'/>}
            </div>}
        </div>
    )
}

export default Dropdown;