import { text as t } from 'shared/text';

export const locationPlayerInfoLayouts = {
    tableHeaders: {
        day: t.day, 
        newPlayers: t.newPlayers,
    },

    sizes: {
        day: 'mid',
        newPlayers: 'mid',
    },

    layouts: {
        day: 'text-left',
        newPlayers: 'text-right',
    },

    formats: {
        day: 'simple-date',
    },
    
    subtotals: {
        groupBy: 'all',
        fields: {
            day: 'header',
            newPlayers: 'total',
        },
        layouts: {
            newPlayers: 'text-right'
        },
        formats: {
            day: 'simple-date'
        }
    }
}
