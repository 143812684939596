import './deviceRecyclerHistory.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deviceRecyclerHistoryLayout as layout } from './DeviceRecyclerHistoryLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableListed from 'components/Tables/TableListed';

const DeviceRecyclerHistory = () => {
    const { id } = useParams();
    const { setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { updateTable } = useTables();
    const { callAPIGet } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const tableId = 'device-recycler-history-' + id;

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        getReportData(range);
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id});
    }, []);

    const deviceDetailsCallback = (data) => {
        const type = data.terminalType;
        setHeader(type.name + ': ' + data.terminalId);
        updateDeviceBreadcrumbs(data);
    }

    const getReportData = (range) => {
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.terminalId = id;
        callAPIGet('report-DeviceRecyclerHistory', deviceRecyclerHistoryCallback, params);
    }
    
    const deviceRecyclerHistoryCallback = (data) => {
        setTableRecords(data?.recyclerCountHistory || []);
        setMasked([false]);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
    }

    return (<TableListed
        id={tableId}
        classes='full'
        data={tableRecords}
        layout={layout}
        masked={masked?.[0]}
        searchBarContent={<>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                disabled={masked?.[0]}
                callback={onDateRangePick}
            />
        </>}
    />)
}

export default DeviceRecyclerHistory;
