import { text as t } from 'shared/text';

export const locationPlayersLayout = {

    tableHeaders: {
        phoneNumber: t.phoneNumber,
        playerPin: t.playerPIN,
        cashableBalance: t.balanceCashable,
        nonCashableBalance: t.balanceNonCashable,
    },

    sizes: {
        phoneNumber: 'mid',
        playerPin: 'mid',
        cashableBalance: 'mid',
        nonCashableBalance: 'mid',
    },

    layouts: {
        phoneNumber: 'text-left',
        playerPin: 'text-left',
        cashableBalance: 'text-right',
        nonCashableBalance: 'text-right',
    },

    formats: {
        phoneNumber: 'phone',
        playerPin: 'lastFour',
        cashableBalance: 'currency-decimal',
        nonCashableBalance: 'currency-decimal',
    },

    navigation: {
        playerPin: {
            label: 'playerPin',
            custom: true,
            root: 'location',
            useId: true,
            ending: 'player',
            useType: 'playerPin',
        }
    }
}
