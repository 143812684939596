import './gameroomPerformanceReports.scss';
import { useEffect, useState } from 'react';
import { gameroomPerformanceReportsLayout as layout } from './GameroomPerformanceReportsLayout';
import { deepCopy, getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import * as e from 'shared/excel/excel';
import { text as t} from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';
const tableId = 'location-gameroom-performance'
const companyId = tableId + '-companyId';

const GameroomPerformanceReports = () => {
    const { updateDropdown } = useInputs();
    const { tableData, tableTotals, tableDropdowns,
            getTableColumns, getURLParams,
            updateTable, updateDropdowns } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ masked, setMasked ] = useState([true]);

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPI('companies', companiesListCallback);
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[companyId];
            sorts && updateDropdown(companyId, sorts.value, sorts.label);
        }
    }, [companies])

    const getReportData = (range) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        callAPIGet('report-GameroomPerformance', gamePerformanceReportsCallback, params);
    }

    const gamePerformanceReportsCallback = (data) => {
        setTableRecords(data?.data ? data.data : []);
        setMasked([false]);
    }

    const companiesListCallback = (data) => {
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName'
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => { 
        const dateInfo = {
            type: 'table',
            columns: ['startDate', 'endDate'],
            headers: {
                selector: t.companyName,
                startDate: t.startDate,
                endDate: t.endDate,
            },
            data:[{
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1]),
            }],
            configs: {
                formats: {selector: 'text-wrap'}
            }
        };
        if (tableDropdowns?.[tableId]?.[companyId]) {
            dateInfo.data[0].selector = tableDropdowns[tableId][companyId].label,
            dateInfo.columns.splice(0,0, 'selector');
        }

        const tableRowData = deepCopy(tableData[tableId]);
        if (layout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, layout);
        const tableInfo = e.getExcelTable(t.gameroomPerformance, columns, tableRowData, layout);
        const sizes = columns.map(key => layout.sizes[key]);
        e.exportToFile('Gameroom Performance Report', [dateInfo, tableInfo], sizes);
    }

    return (
        <TableListed
            id={tableId}
            data={tableRecords}
            layout={layout}
            masked={masked?.[0]}
            searchBarContent={<>
                <Dropdown
                    id={companyId}
                    classes='quarter'
                    label={t.companyName}
                    masked={masked?.[0]}
                    data={companies}
                    nullable={true}
                    hideErrors={true}
                    callback={onCompanySelect}/>
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    disabled={masked?.[0]}
                    callback={onDateRangePick}/>
                <Button 
                    classes='green sixth match-labeled-input'
                    disabled={masked?.[0]}
                    onClick={onExportToExcel}
                >{t.exportToExcel}</Button>
            </>}
        /> 
    )
}

export default GameroomPerformanceReports;
